<template>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12">
                <div class="col-12 col-lg-4 g">
                    <span>البحث</span>
                    <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; getArchive();">
                </div>
            </div>
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th>
                            المستخدم
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            التقييم
                        </th>
                        <th>
                            تمت رؤيتها
                        </th>
                        <th>
                            الموضوع
                        </th>
                        <th>
                            ردك
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user._id">
                            <td>
                                <span v-if="user.user">
                                    <span v-if="user.user._id">
                                        <a target="_blank" :href="'https://s.tahdir.net/autologin/' + user.user.jwt">
                                        <i class="fa fa-external-link"></i>
                                        {{user.user.name}} </a>
                                    </span>
                                </span>
                            </td>
                            <td>
                                {{ user.date }}
                            </td>
                            <td>
                                {{ user.service }}
                            </td>
                            <td>
                                {{ user.rate }}
                            </td>
                            <td>
                                <span v-if="user.seen">نعم</span>
                                <span v-if="!user.seen" @click="seen(user._id)" class="text-danger">تعيين كمرئية</span>
                            </td>
                            <td>
                                {{ user.content }}
                            </td>
                            <td>
                                {{ user.admin_reply }}
                                <button class="btn btn-sm btn-success" @click="reply(user._id)">رد</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            loading: true,
            loading2: true,
            jwt: localStorage.getItem('jwt'),
            users: [],
            q: "",
            page: 0,
            services: [],
            selectedUser: {}
        }
    },
    created(){
        var g = this;
        g.gett();
    },
    methods: {
        gett(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/feedbacks', {
                jwt: this.jwt,
                q: this.q,
                page: this.page,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.users = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        seen(id){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/feedbacks/seen', {
                jwt: this.jwt,
                id: id
            }).then(function(r){
                g.gett()
            }).fail(function(){
                g.loading = false;
            })
        },
        reply(id){
            var g = this;
            g.loading = true;
            var t = prompt("ردك", "");
            if(t && t != ""){
                $.post(api + '/admin/feedbacks/reply', {
                jwt: this.jwt,
                id: id,
                reply: t
            }).then(function(r){
                g.gett()
            }).fail(function(){
                g.loading = false;
            })
            }
        },
    }
}
</script>
<style scoped>
</style>